import { APP_TITLE, APP_DESCRIPTION, NOTIFICATION_TIMEOUT } from '@/config/app';

export const useAppStore = defineStore('app', {
  historyEnabled: true,
  persist: {
    paths: ['params'],
  },

  state: () => ({
    page: {
      title: '',
      path: '',
      pathName: '',
    },
    pageTransition: {
      name: 'router-view',
      mode: 'in-out',
    },
    params: {
      section: '',
      category: '',
      subcategory: '',
    },
    isSectionTitleVisible: true,
    showBackButton: false,
    isMobile: false,
    isTablet: false,
    toastTimeout: 10000,
    currentLayout: 'loadingLayout',
  }),

  getters: {
    appName() {
      return import.meta.env.VITE_APP_NAME;
    },
    appTitle(state) {
      return state.page.title + ' | ' + this.appName || APP_TITLE;
    },
    appDescription() {
      return import.meta.env.VITE_APP_DESCRIPTION || APP_DESCRIPTION;
    },
    appToastTimeout(state) {
      return NOTIFICATION_TIMEOUT || state.toastTimeout;
    },
    paginationPerPage(state) {
      return state.isMobile ? 10 : 25;
    },
  },

  actions: {
    setPageTransition(value) {
      if ('default' === value) {
        this.pageTransition.name = 'router-view';
        this.pageTransition.mode = 'in-out';
      }

      if ('back' === value) {
        this.pageTransition.name = 'router-view-back';
        this.pageTransition.mode = '';
      }
    },

    setParams(params) {
      this.params = params;
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAppStore, import.meta.hot));
}
