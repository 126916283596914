const getFileNameFromUrl = url => {
  const parts = url.split('/');
  const filenameWithExtension = parts[parts.length - 1];

  // Find the position of the last dot in the filename
  const dotPosition = filenameWithExtension.lastIndexOf('.');

  // If there is no dot in the filename, return the full filename
  if (dotPosition === -1) {
    return filenameWithExtension;
  }

  // Otherwise, return the part of the filename before the last dot
  return filenameWithExtension.slice(0, dotPosition);
};

export default getFileNameFromUrl;
