import fetch from '@/api/clients/rest';

export default {
  async csrf() {
    return await fetch().get('/sanctum/csrf-cookie');
  },
  async register(data) {
    return await fetch().post('/register', data);
  },
};
