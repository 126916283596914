export const mainNavigation = [
  {
    url: '/2-rocnik',
    text: '2.Ročník',
    icon: PhMedal,
  },
  {
    url: '/1-rocnik',
    text: '1.Ročník',
    icon: PhMedal,
  },
];

export const mobileNavigation = [
  {
    url: '/uvod',
    text: 'Úvod',
    icon: PhHouseLine,
  },
  {
    url: '/2-rocnik',
    text: '2.Ročník',
    icon: PhMedal,
  },
  {
    url: '/1-rocnik',
    text: '1.Ročník',
    icon: PhMedal,
  },
];
