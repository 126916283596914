<script setup>
const route = useRoute();
const router = useRouter();

const dataStore = useDataStore();

const props = defineProps({
  title: {
    type: String,
    required: false,
    default: null,
  },
});

const close = () => {
  dataStore.isProcessing = false;
  const { modal, ...newQuery } = route.query;
  router.push({ path: route.path, query: newQuery });
};
</script>

<template>
  <div class="Modal">
    <header class="Modal__Header">
      <h3 class="Modal__Header__Title" v-if="title">{{ title }}</h3>
      <slot name="header" />
      <VButton @click="close" text="Zatvoriť" />
    </header>
    <main class="Modal__Content">
      <slot name="content" />
    </main>
    <footer class="Modal__Footer">
      <slot name="footer" />
    </footer>
  </div>
</template>

<style scoped lang="scss">
.Modal {
  @include border-radius(top, $size: size(40px));

  align-content: start;
  background-color: color(primary, 100);
  color: color(white);
  display: grid;
  height: size(screen);
  left: 0;
  margin: auto;
  position: fixed;
  right: 0;
  width: size(full);
  top: size(20px);

  @media only screen and (min-width: get-breakpoint(widetablet)) {
    @include border-radius($size: size(40px));

    height: auto;
    max-width: calc(2 * size(450px));
  }

  &__Header,
  &__Footer {
    @include align-items(center);
    @include flexbox;
    @include justify-content(space-between);
  }

  &__Header {
    padding: size(16px);

    @media only screen and (min-width: get-breakpoint(widetablet)) {
      padding: size(40px) size(40px) size(none);
    }

    &__Title {
      color: color(white);
      font-size: size(32px);
      text-shadow: size(1px) size(1px) color(black);
    }
  }

  &__Content {
    @include flexbox;
    @include grid-gap(size(20px));

    align-content: start;
    display: grid;
    padding-inline: size(8px);
    overflow: auto;

    @media only screen and (min-width: get-breakpoint(widetablet)) {
      padding: size(20px);
    }
  }
}
</style>
