export default async function fetchNotes(to, from, next) {
  const appStore = useAppStore();

  const variables = reactive({
    section: to.params.section,
    category: to.params.category,
    subcategory: to.params.subcategory,
  });

  await appStore.setParams(variables);

  next();
}
