export const useNotificationsStore = defineStore('notifications', {
  historyEnabled: true,
  persist: true,

  state: () => ({
    notifications: [],
  }),

  getters: {},

  actions: {
    async add(notification, type = 'info', timeout = true) {
      const appStore = useAppStore();

      this.notifications.push({ notification: notification, type: type });

      if (timeout) {
        setTimeout(() => {
          this.remove(notification, type);
        }, appStore.appToastTimeout);
      }
    },

    async remove(notification, type = 'info') {
      const indexToRemove = this.notifications.findIndex(
        (item) => item.notification === notification && item.type === type
      );

      if (indexToRemove !== -1) {
        this.notifications.splice(indexToRemove, 1);
      }
    },

    async clear() {
      this.notifications = [];
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useNotificationsStore, import.meta.hot)
  );
}
