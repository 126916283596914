<script setup>
const props = defineProps({
  text: {
    type: String,
  },
  loadingText: {
    type: String,
    default: 'Odosielam...',
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  isActive: {
    type: Boolean,
    default: false,
  },
  keybind: {
    type: String,
  },
  color: {
    type: String,
  },
  position: {
    type: String,
  },
  size: {
    type: String,
  },
  icon: {
    type: Object,
  },
  iconWeight: {
    type: String,
    default: 'bold',
  },
  iconSize: {
    type: Number,
    default: 16,
  },
  hasFullWidth: {
    type: Boolean,
  },
});

const getCss = () => {
  const css = ['Button'];

  if (props.color) css.push(` Button--${props.color}`);
  if (props.position) css.push(` Button--${props.position}`);
  if (props.size) css.push(` Button--${props.size}`);
  if (props.hasFullWidth) css.push(` Button--HasFullWidth`);
  if (props.isLoading) css.push(` Button--IsLoading`);

  return css;
};

onMounted(() => getCss());
</script>

<template>
  <button :class="getCss()" :disabled="isLoading" :active="isActive">
    <VPIcon v-if="icon" :name="icon" :size="iconSize" :weight="iconWeight" />
    <template v-if="isLoading">{{ loadingText }}</template>
    <template v-else>
      {{ text }}
      <span v-if="keybind" class="Button__Keybind">{{ keybind }}</span>
    </template>
    <slot />
    <VPIcon
      v-if="color === 'Tab'"
      :name="PhCaretDown"
      :size="20"
      weight="bold"
      class="Button__ActiveIcon"
    />
  </button>
</template>

<style lang="scss">
.Button {
  @include border-radius($size: size(40px));
  @include grid-gap(size(8px));
  @include inline-flexbox;
  @include transition(all duration(200) ease-in-out);

  align-items: center;
  background-color: color(primary, 90);
  border: none;
  color: color(primary, 20);
  cursor: pointer;
  font-family: font-family(secondary);
  font-size: size(24px);
  font-weight: font-weight(bold);
  padding: size(12px) size(20px);
  position: relative;
  width: fit-content;
  text-align: center;
  text-transform: uppercase;

  &__Keybind {
    @include border-radius($size: size(4px));

    background: color(secondary, 60);
    color: color(primary, 70);
    font-size: size(10px);
    padding: size(2px) size(4px);
  }

  &__ActiveIcon {
    bottom: 0;
    content: attr(icon);
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    transition: transform 400ms cubic-bezier(0.47, 1.64, 0.41, 0.8);
    transform: translateY(size(none));
  }

  &[active='false'] {
    .Button__ActiveIcon {
      opacity: 0;
    }
  }

  &[active='true'],
  &:hover {
    .Button__ActiveIcon {
      transform: translateY(size(8px));
      opacity: 1;
    }
  }

  .icon {
    height: size(24px);
    width: size(24px);
  }

  &:hover {
    background-color: color(primary, 90);
  }

  &:hover,
  &:focus {
    box-shadow: 0 0 0 2px color(primary, 80);
    outline: none;
  }

  &:active {
    top: 1px;
  }

  &--Primary {
    background-color: color(primary, 50);
    color: color(black);

    &:hover {
      background-color: color(primary, 40);
    }

    &:hover,
    &:focus {
      box-shadow: 0 0 0 2px color(primary, 30);
    }

    &:disabled {
      background-color: color(primary, 60);
      color: color(primary, 10);
      cursor: not-allowed;
    }
  }

  &--Secondary {
    background-color: color(secondary, 50);
    color: color(white);

    &:hover {
      background-color: color(secondary, 60);
    }
  }

  &--Danger {
    background-color: color(red, 10);
    color: color(red);

    &:hover {
      background-color: color(red, 10);
    }
  }

  &--Tab {
    border-top: size(2px) solid color(secondary, 50);
    color: color(secondary, 50);

    &:hover[active='false'] {
      background: linear-gradient(color(secondary, 80), transparent 50%);
    }

    &[active='true'] {
      background: linear-gradient(color(primary, 30a), transparent 50%);
      border-color: color(primary, 50);
      color: color(primary, 50);
    }
  }

  &--Right {
    justify-self: right;
  }

  &--Small {
    font-size: size(12px);
    padding: size(8px) size(16px);
  }

  &--HasFullWidth {
    @include justify-content(center);

    width: size(full);
  }

  &--IsLoading {
    &:disabled {
      cursor: wait;
    }
  }

  &:disabled {
    background-color: color(primary, 90);
    color: color(primary, 80);
    cursor: not-allowed;

    &:hover,
    &:focus {
      box-shadow: 0 0 0 2px color(red);
    }
  }
}

::-moz-selection {
  background: color(primary, 50);
  text-shadow: none;
}

::selection {
  background: color(primary, 50);
  text-shadow: none;
}
</style>
