import { dataAPI } from '@/api';

export const useDataStore = defineStore('data', {
  historyEnabled: true,

  state: () => ({
    data: {},
    isLoading: false,
    isProcessing: true,
    response: {
      success: false,
      message:
        '<strong>Registrácia je možná už len na mieste od 15:00 do 15:45 v deň súťaže.</strong>',
      errors: null,
    },
  }),

  getters: {},

  actions: {
    async register(data) {
      try {
        await this.handle();
        await this.setProcessing(true);
        await this.setLoading(true);
      } catch (error) {
        await this.handleError(error.response.data);
      } finally {
        setTimeout(async () => {
          await this.setLoading(false);
        }, 2500);
      }
      // await this.handleProcess(() => dataAPI.register(data));
    },

    async handleProcess(apiCall) {
      try {
        await this.setProcessing(true);
        await this.setLoading(true);
        const response = await apiCall();
        await this.handleSuccess(response);
      } catch (error) {
        await this.handleError(error.response.data);
      } finally {
        setTimeout(async () => {
          await this.setLoading(false);
        }, 2500);
      }
    },

    async handle() {
      this.response.message =
        '<strong>Registrácia je možná už len na mieste od 15:00 do 15:45 v deň súťaže.';

      this.response.success = false;
    },

    async handleSuccess(response) {
      if (response.data.data?.age <= 12) {
        this.response.message =
          '<strong>Úspešne ste sa zaregistrovali.</strong><br />Na E-mail sme Vám poslali potrebné informácie.';
      } else {
        this.response.message =
          '<strong>Úspešne ste sa zaregistrovali.</strong><br />Na E-mail sme Vám poslali potrebné informácie.<br />Nezabudnite uhradiť platbu <strong>6€</strong> do 24.7.2024.<br />Pokiaľ platbu nestihnete v termíne, bude možné zaplatiť už iba <strong>na mieste 10€</strong>.';
      }
      this.response.success = true;
    },

    async handleError(error) {
      if (error.status === 'error') {
        this.response.errors = error.data;

        await this.setProcessing(false);
      } else {
        this.response.message =
          '<strong>Ospravedlňujeme sa, ale pri registrácii nastala chyba.</strong><br />Skúste to neskôr.';

        this.response.success = false;
      }
    },

    async updateResponse(message, success, errors = null) {
      this.response.success = success;
      this.response.message = message;
      this.response.errors = errors;
    },

    async setLoading(state) {
      this.isLoading = state;
    },

    async setProcessing(state) {
      this.isProcessing = state;
    },

    clearErrors() {
      this.response.message = null;
      this.response.errors = null;
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDataStore, import.meta.hot));
}
