<script setup>
/**
 * Import packages
 */
import { defineComponent, ref, onMounted, onUnmounted } from 'vue';
import { vOnClickOutside } from '@vueuse/components';
import { useWindowScroll } from '@vueuse/core';
import { PhX, PhArrowFatLineLeft, PhArrowFatLineRight } from '@phosphor-icons/vue';

import getFileNameFromUrl from '@/helpers/getFileNameFromUrl';
/**
 * Component Name
 */
defineComponent({
  name: 'TheModal',
});

/**
 * Component Props
 */
const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    required: false,
    default: '',
  },
  photos: {
    type: Array,
    required: true,
    default: () => [],
  },
  photoIndex: {
    type: Number,
    required: false,
    default: 0,
  },
});

let image = ref(props.photos[props.photoIndex]);

let index = ref(props.photoIndex);

/**
 * On component Mount
 */
onMounted(async () => {
  const { y } = useWindowScroll();
  document.body.classList.add('gallery-open');
  document.body.style.top = `${-y.value}px`;
});

/**
 * On component Unmount
 */
onUnmounted(() => {
  const scrollY = document.body.style.top;
  document.body.classList.remove('gallery-open');
  document.body.style.top = '';
  window.scrollTo(0, parseInt(scrollY || '0') * -1);
});

/**
 * Component Emits
 */
const emit = defineEmits(['close']);

/**
 * Functions
 */
const closeModal = () => {
  emit('close');
};
const next = () => {
  index.value = (index.value + 1) % props.photos.length;
  image.value = props.photos[index.value];
};
const previous = () => {
  index.value = (index.value - 1) % props.photos.length;
  image.value = props.photos[index.value];
};
</script>

<template>
  <Teleport to="body">
    <div class="gallery">
      <div v-on-click-outside="closeModal" class="gallery__wrapper">
        <h3 class="gallery__title">
          {{ title }}
          <button class="gallery__title__close" @click="closeModal">
            Zatvoriť
            <PhX weight="bold" class="gallery__title__close__icon" />
          </button>
        </h3>

        <div class="gallery__body">
          <button class="gallery__body__previous" :disabled="index === 0" @click="previous">
            <PhArrowFatLineLeft weight="duotone" class="gallery__body__previous__icon" />
          </button>
          <img
            :src="image.path"
            :alt="getFileNameFromUrl(image.path)"
            class="gallery__body__photo"
          />
          <button class="gallery__body__next" :disabled="index === photos.length - 1" @click="next">
            <PhArrowFatLineRight class="gallery__body__next__icon" weight="duotone" />
          </button>
        </div>
        <!-- <div class="gallery__footer">
          <PhotosPhoto
            v-for="(photo, index) of photos"
            :key="index"
            :path="photo.path"
            @click="showGallery(index)"
          />
        </div> -->
      </div>
    </div>
  </Teleport>
</template>

<style lang="scss">
.gallery {
  background: #000000c9;
  position: fixed;
  display: grid;
  inset: 0;

  &__wrapper {
    -webkit-animation-name: zoom;
    -webkit-animation-duration: 0.6s;
    animation-name: zoom;
    animation-duration: 0.6s;
  }

  &__title {
    align-items: center;
    color: color(white);
    display: flex;
    font-size: size(32px);
    font-weight: font-weight(bold);
    justify-content: space-between;
    max-inline-size: none;
    width: size(full);

    &__close {
      @include border-radius-button;
      @include grid-gap(4px);

      align-items: center;
      background-color: color(primary, 50);
      color: color(black);
      display: flex;
      font-size: size(16px);
      line-height: size(14px);
      padding: size(8px) size(12px);
      text-transform: uppercase;

      &:hover {
        background: color(primary, 40);
      }
    }
  }

  &__title,
  &__body {
    padding: size(16px);
  }

  &__body {
    margin: auto;
    position: relative;
    height: calc(size(screen) - size(64px));
    display: flex;
    align-items: center;
    justify-content: center;

    &__photo {
      @include border-radius($size: size(24px));

      max-height: -webkit-fill-available;
      // width: calc(0.8 * size(screen));
    }

    &__previous,
    &__next {
      @include flexbox;
      align-items: center;
      bottom: 0;
      position: absolute;
      top: 0;
      z-index: z-index(5);

      &__icon {
        background: color(primary, 40);
        fill: color(black);
        height: size(32px);
        padding: size(4px);
        width: size(32px);
      }

      &[disabled] {
        .gallery__body__next__icon,
        .gallery__body__previous__icon {
          background: color(grey, 60);
          cursor: default;
          fill: color(grey, 80);
        }
      }
    }

    &__previous {
      left: size(none);

      &__icon {
        @include border-radius(right);
      }
    }

    &__next {
      right: size(none);

      &__icon {
        @include border-radius(left);
      }
    }
  }

  &__footer {
    display: flex;
    overflow: hidden;
  }

  .form {
    background: color(grey, 10);
    @include border-radius-box;
    @include flexbox;
    @include flex-direction(row);
    gap: size(16px);
    height: size(full);

    &__main {
      @include flex;
      display: grid;
      @include grid-gap(size(8px));
      grid-template-rows: max-content;
      padding: spacer(box);
    }

    &__aside {
      align-content: space-between;
      background: color(grey, 30);
      @include border-radius-box;
      display: grid;
      width: 30%;

      &__options {
        display: grid;
        @include grid-gap(size(8px));
        padding: spacer(box);
      }

      &__buttons {
        display: grid;
        background: color(grey, 90);
        @include border-radius-box;
        @include grid-gap(size(8px));
        @include grid-columns(2);
        padding: spacer(box);
      }
    }
  }

  .successful {
    background: color(white);
    @include border-radius-box;
    padding: spacer(box);
  }

  .box-header {
    h3,
    h4 {
      font-family: font-family(secondary);
      padding-bottom: size(base);

      span {
        font-weight: get-font-weight(semibold);
      }
    }
  }

  &.match {
    text-align: center;

    .box-header {
      border-bottom: 1px solid color(light);
      position: relative;

      h4 {
        border-bottom: 1px solid;
        display: inline-block;
        margin-bottom: -1px;
      }
    }
  }

  .box-body {
    padding: size(xl);
  }
}

@media only screen and (min-width: get-breakpoint(desktop)) {
  .gallery {
    &__body {
      &__photo {
        height: calc(0.8 * size(screen));
        width: auto;
      }
    }
  }
}

@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
  }
}

@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 4s linear;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.pop-enter-active,
.pop-leave-active {
  transition: transform 0.4s cubic-bezier(0.5, 0, 0.5, 1), opacity 0.4s linear;
}

.pop-enter,
.pop-leave-to {
  opacity: 0;
  transform: scale(0.3) translateY(-50%);
}
</style>
