import axios from 'axios';
import Cookies from 'js-cookie';

const fetch = (url = import.meta.env.VITE_API_URL) => {
  return axios.create({
    baseURL: url,
    headers: {
      'Content-type': 'application/json',
      'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN'),
    },
    withCredentials: true,
  });
};

export default fetch;
