import { createRouter, createWebHistory } from 'vue-router';
// import { routes } from 'vue-router/auto-routes';

import authGuard from '@/router/guards/auth';
import paramsGuard from '@/router/guards/params';
import transitionGuard from '@/router/guards/transition';

const breakpoints = useBreakpoints({
  widetablet: 832,
});

const transitionName = computed(() => route.meta.transition);

let isWidetablet = breakpoints.greater('widetablet');

const routes = [
  {
    path: '/',
    redirect: (to) => {
      if (isWidetablet.value) {
        return { path: '/2-rocnik' };
      } else {
        return { path: '/uvod' };
      }
    },
  },
  {
    path: '/uvod',
    name: 'Úvod',
    component: () => import('@/pages/index.vue'),
    meta: {
      title: 'Úvod',
      scrollToTop: true,
    },
  },
  // {
  //   path: '/vyhry-a-startovne',
  //   name: 'Výhry a Štartovné',
  //   component: () => import('@/pages/vyhry-a-startovne.vue'),
  //   meta: {
  //     title: 'Výhry a Štartovné',
  //     scrollToTop: true,
  //   },
  // },
  // {
  //   path: '/registracia-a-platba',
  //   name: 'Registrácia a Platba',
  //   component: () => import('@/pages/registracia-a-platba.vue'),
  //   meta: {
  //     title: 'Registrácia a Platba',
  //     scrollToTop: true,
  //   },
  // },
  {
    path: '/2-rocnik',
    name: '2. Ročník',
    component: () => import('@/pages/2-rocnik.vue'),
    meta: {
      title: '2. Ročník',
      scrollToTop: true,
    },
  },
  {
    path: '/1-rocnik',
    name: '1. Ročník',
    component: () => import('@/pages/1-rocnik.vue'),
    meta: {
      title: '1. Ročník',
      scrollToTop: true,
    },
  },
  // {
  //   path: '/oou',
  //   name: 'Ochrana osobných údajov',
  //   component: () => import('@/pages/oou.vue'),
  //   meta: {
  //     title: 'Ochrana osobných údajov',
  //     scrollToTop: true,
  //   },
  // },
  {
    path: '/:pathMatch(.*)*',
    redirect: (to) => {
      if (isWidetablet.value) {
        return { path: '/vyhry-a-startovne' };
      } else {
        return { path: '/uvod' };
      }
    },
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_URL),

  scrollBehavior(to) {
    if (to.meta.scrollToTop) {
      return { top: 0, left: 0 };
    }
  },

  linkActiveClass: 'Navigation__Item--Active',
  linkExactActiveClass: 'Navigation__Item--ExactActive',

  routes,
});

const routeOrder = routes.map((route) => route.name);
let isInitialLoad = true;

router.beforeEach((to, from, next) => {
  if (isInitialLoad) {
    to.meta.transition = 'fade';
    isInitialLoad = false;
  } else {
    const toIndex = routeOrder.indexOf(to.name);
    const fromIndex = routeOrder.indexOf(from.name);

    if (toIndex > fromIndex) {
      to.meta.transition = 'slide-right';
    } else {
      to.meta.transition = 'slide-left';
    }
  }
  next();
});

router.beforeEach(paramsGuard);
// router.beforeEach(authGuard);
// router.beforeEach(transitionGuard);

export { router, routeOrder };
